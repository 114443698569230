<template>
  <div class="flex-grow mb-12 flex justify-center flex-col items-center">
    <div class="flex justify-center flex-col items-center">
      <h1 class="text-2xl md:text-4xl text-white font-bold">Թվագիտական Մատրիցա</h1>
      <img width="100px" class="rounded-full my-6" :src="logo">
    </div>
    <div class="relative max-w-[250px]">
      <p class="text-white mb-2">օր ամիս տարի</p>
      <input
          v-model="date"
          ref="dateInput"
          aria-label="date"
          type="text"
          name="date"
          class="w-full border-2 border-blue-300 bg-white rounded-lg text-lg font-normal h-16 px-4 text-center"
      />
      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <div class="text-red-500"></div>
      </div>
      <button @click="calculateDate" type="button"
              class="mt-5 w-full text-white text-lg text-xl bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">
        Հաշվել
      </button>
    </div>

    <!-- STARTS -->
    <div v-show="isCalculate" id="starsComponent" class="flex w-full">
      <div class="mx-auto w-full">
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->
          <div
              :class="[
      'absolute top-[115px] w-[257px] left-[32px]',
      'md:top-[115px] md:left-[67px] md:w-[347px]',
      'lg:top-[115px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[115px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="firstStar"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
        'absolute top-[182px] left-[0px] w-[44px]',
        'md:top-[219px] md:left-[6px]',
        'lg:top-[243px] lg:left-[47px]',
        'xl:top-[243px] xl:left-[207px]',
        '2xl:left-[327px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ topLeftStarPeak }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
        'absolute top-[75px] left-[135px] w-[44px]',
        'md:top-[55px] md:left-[217px]',
        'lg:top-[55px] lg:left-[297px]',
        'xl:top-[55px] xl:left-[457px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ topCenterStarPeak }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
        'absolute top-[182px] left-[280px] w-[44px]',
        'md:top-[218px] md:left-[433px]',
        'lg:top-[243px] lg:left-[548px]',
        'xl:top-[243px] xl:left-[708px]',
        '2xl:left-[828px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ topRightStarPeak }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
        'absolute top-[360px] left-[220px] w-[44px]',
        'md:top-[459px] md:left-[340px]',
        'lg:top-[515px] lg:left-[440px]',
        'xl:top-[515px] xl:left-[617px]',
        '2xl:left-[717px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ underRightStarPeak }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
        'absolute top-[360px] left-[50px] w-[44px]',
        'md:top-[459px] md:left-[73px]',
        'lg:top-[515px] lg:left-[147px]',
        'xl:top-[515px] xl:left-[307px]',
        '2xl:left-[427px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ underLeftStarPeak }}</div>
            </div>

            <!-- Text Element 6 ("8") -->
            <div
                :class="[
        'absolute top-[233px] left-[135px] w-[44px]',
        'md:top-[280px] md:left-[215px]',
        'lg:top-[313px] lg:left-[290px]',
        'xl:top-[313px] xl:left-[453px]',
        '2xl:left-[578px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ sumOfAllPeakForCenter }}</div>
            </div>

            <!-- Text Element 7 ("7") -->
            <div
                :class="[
        'absolute top-[182px] left-[90px] w-[44px]',
        'md:top-[205px] md:left-[155px]',
        'lg:top-[217px] lg:left-[225px]',
        'xl:top-[215px] xl:left-[390px]',
        '2xl:left-[497px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ dayAndMontSum }}</div>
            </div>

            <!-- Text Element 8 ("6") -->
            <div
                :class="[
        'absolute top-[260px] left-[205px] w-[44px]',
        'md:top-[312px] md:left-[312px]',
        'lg:top-[353px] lg:left-[400px]',
        'xl:top-[353px] xl:left-[565px]',
        '2xl:left-[694px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ sumOfYearSeparateSumAndDayYearMonth }}</div>
            </div>

            <!-- Text Element 9 ("6") -->
            <div
                :class="[
        'absolute top-[305px] left-[135px] w-[44px]',
        'md:top-[385px] md:left-[215px]',
        'lg:top-[425px] lg:left-[294px]',
        'xl:top-[425px] xl:left-[458px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ sumOfUnderLeftAndRightNumbers }}</div>
            </div>

            <!-- Text Element 10 ("8") -->
            <div
                :class="[
        'absolute top-[255px] left-[65px] w-[44px]',
        'md:top-[312px] md:left-[110px]',
        'lg:top-[350px] lg:left-[180px]',
        'xl:top-[353px] xl:left-[347px]',
        '2xl:left-[460px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ sumOfTopLeftAndUnderLeftNumbers }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
        'absolute top-[182px] left-[180px] w-[44px]',
        'md:top-[205px] md:left-[270px]',
        'lg:top-[215px] lg:left-[378px]',
        'xl:top-[215px] xl:left-[520px]',
        '2xl:left-[656px]'
      ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ dayAndYearSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Ճակատագրի կոդ-->
        <div class="mt-16 mb-24">
          <h1 class="text-xl md:text-3xl text-white font-bold ">
            Ճակատագրի կոդ
          </h1>
          <div class="text-3xl text-red-500 md:text-[40px] font-thin mt-8"> {{ fateCodeNumber }}</div>
        </div>

        <!-- Ինքնության Եռանկյունի-->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl text-white font-bold ">
            Ինքնության Եռանկյունի
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->

          <div
              :class="[
              'absolute top-[95px] w-[257px] left-[32px]',
              'md:top-[95px] md:left-[67px] md:w-[347px]',
              'lg:top-[95px] lg:left-[111px] lg:w-[417px]',
              'xl:top-[95px] xl:left-[271px] xl:w-[417px]',
              '2xl:left-[396px] 2xl:w-[417px]'
            ]"
          >
            <img
                :src="triangle"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
                'absolute top-[200px] left-[50px] w-[44px]',
                'md:top-[240px] md:left-[90px]',
                'lg:top-[275px] lg:left-[150px]',
                'xl:top-[275px] xl:left-[317px]',
                '2xl:left-[435px]'
              ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ personalityLeftUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
                'absolute top-[60px] left-[140px] w-[44px]',
                'md:top-[55px] md:left-[217px]',
                'lg:top-[55px] lg:left-[297px]',
                'xl:top-[55px] xl:left-[457px]',
                '2xl:left-[577px]'
              ]"
            >
              <div class="text-2xl text-[#cb0dc3] md:text-[40px] font-thin"> {{ personalityTop }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
                'absolute top-[200px] left-[225px] w-[44px]',
                'md:top-[240px] md:left-[345px]',
                'lg:top-[275px] lg:left-[450px]',
                'xl:top-[275px] xl:left-[600px]',
                '2xl:left-[740px]'
              ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ personalityRightUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
                'absolute top-[315px] left-[265px] w-[44px]',
                'md:top-[400px] md:left-[395px]',
                'lg:top-[465px] lg:left-[505px]',
                'xl:top-[465px] xl:left-[665px]',
                '2xl:left-[790px]'
              ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ personalityUnderRight }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
                'absolute top-[315px] left-[5px] w-[44px]',
                'md:top-[400px] md:left-[30px]',
                'lg:top-[465px] lg:left-[90px]',
                'xl:top-[465px] xl:left-[245px]',
                '2xl:left-[370px]'
              ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ personalityUnderLeft }}</div>
            </div>


            <!-- Text Element 7 ("7") -->
            <div
                :class="[
                'absolute top-[182px] left-[115px] w-[44px]',
                'md:top-[215px] md:left-[190px]',
                'lg:top-[250px] lg:left-[255px]',
                'xl:top-[250px] xl:left-[420px]',
                '2xl:left-[540px]'
              ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ personalityLeftInsideSum }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
                'absolute top-[182px] left-[160px] w-[44px]',
                'md:top-[215px] md:left-[250px]',
                'lg:top-[250px] lg:left-[335px]',
                'xl:top-[250px] xl:left-[500px]',
                '2xl:left-[620px]'
              ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ personalityRightInsideSum }}</div>
            </div>
            <div
                :class="[
                'absolute top-[325px] left-[140px] w-[44px]',
                'md:top-[420px] md:left-[215px]',
                'lg:top-[480px] lg:left-[294px]',
                'xl:top-[480px] xl:left-[458px]',
                '2xl:left-[577px]'
              ]"
            >
              <div class="text-2xl md:text-[40px] text-[#bd5928] font-thin">{{ personalityRightAndLeftUnderSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Հոգևոր Եռանկյունի-->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl  text-white font-bold">
            Հոգևոր Եռանկյունի
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->

          <div
              :class="[
      'absolute top-[95px] w-[257px] left-[32px]',
      'md:top-[95px] md:left-[67px] md:w-[347px]',
      'lg:top-[95px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[95px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="triangle"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
        'absolute top-[200px] left-[50px] w-[44px]',
        'md:top-[240px] md:left-[90px]',
        'lg:top-[275px] lg:left-[150px]',
        'xl:top-[275px] xl:left-[317px]',
        '2xl:left-[435px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ spiritualityLeftUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
        'absolute top-[60px] left-[140px] w-[44px]',
        'md:top-[55px] md:left-[217px]',
        'lg:top-[55px] lg:left-[297px]',
        'xl:top-[55px] xl:left-[457px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#cb0dc3] md:text-[40px] font-thin">{{ spiritualityTop }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
        'absolute top-[200px] left-[225px] w-[44px]',
        'md:top-[240px] md:left-[345px]',
        'lg:top-[275px] lg:left-[450px]',
        'xl:top-[275px] xl:left-[600px]',
        '2xl:left-[740px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ spiritualityRightUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
        'absolute top-[315px] left-[265px] w-[44px]',
        'md:top-[400px] md:left-[395px]',
        'lg:top-[465px] lg:left-[505px]',
        'xl:top-[465px] xl:left-[665px]',
        '2xl:left-[790px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ spiritualityUnderRight }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
        'absolute top-[315px] left-[5px] w-[44px]',
        'md:top-[400px] md:left-[30px]',
        'lg:top-[465px] lg:left-[90px]',
        'xl:top-[465px] xl:left-[245px]',
        '2xl:left-[370px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ spiritualityUnderLeft }}</div>
            </div>


            <!-- Text Element 7 ("7") -->
            <div
                :class="[
        'absolute top-[182px] left-[115px] w-[44px]',
        'md:top-[215px] md:left-[190px]',
        'lg:top-[250px] lg:left-[255px]',
        'xl:top-[250px] xl:left-[420px]',
        '2xl:left-[540px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ spiritualityLeftInsideSum }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
        'absolute top-[182px] left-[160px] w-[44px]',
        'md:top-[215px] md:left-[250px]',
        'lg:top-[250px] lg:left-[335px]',
        'xl:top-[250px] xl:left-[500px]',
        '2xl:left-[620px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ spiritualityRightInsideSum }}</div>
            </div>
            <div
                :class="[
        'absolute top-[325px] left-[140px] w-[44px]',
        'md:top-[420px] md:left-[215px]',
        'lg:top-[480px] lg:left-[294px]',
        'xl:top-[480px] xl:left-[458px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ spiritualityRightAndLeftUnderSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Ֆինանսական Եռանկյունի-->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl  text-white font-bold">
            Ֆինանսական Եռանկյունի
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->

          <div
              :class="[
      'absolute top-[95px] w-[257px] left-[32px]',
      'md:top-[95px] md:left-[67px] md:w-[347px]',
      'lg:top-[95px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[95px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="triangle"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
        'absolute top-[200px] left-[50px] w-[44px]',
        'md:top-[240px] md:left-[90px]',
        'lg:top-[275px] lg:left-[150px]',
        'xl:top-[275px] xl:left-[317px]',
        '2xl:left-[435px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ financeTriangleLeftUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
        'absolute top-[60px] left-[140px] w-[44px]',
        'md:top-[55px] md:left-[217px]',
        'lg:top-[55px] lg:left-[297px]',
        'xl:top-[55px] xl:left-[457px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#cb0dc3] md:text-[40px] font-thin">{{ financeTriangleTop }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
        'absolute top-[200px] left-[225px] w-[44px]',
        'md:top-[240px] md:left-[345px]',
        'lg:top-[275px] lg:left-[450px]',
        'xl:top-[275px] xl:left-[600px]',
        '2xl:left-[740px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ financeTriangleRightUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
        'absolute top-[315px] left-[265px] w-[44px]',
        'md:top-[400px] md:left-[395px]',
        'lg:top-[465px] lg:left-[505px]',
        'xl:top-[465px] xl:left-[665px]',
        '2xl:left-[790px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ financeTriangleUnderRight }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
        'absolute top-[315px] left-[5px] w-[44px]',
        'md:top-[400px] md:left-[30px]',
        'lg:top-[465px] lg:left-[90px]',
        'xl:top-[465px] xl:left-[245px]',
        '2xl:left-[370px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ financeTriangleUnderLeft }}</div>
            </div>


            <!-- Text Element 7 ("7") -->
            <div
                :class="[
        'absolute top-[182px] left-[115px] w-[44px]',
        'md:top-[215px] md:left-[190px]',
        'lg:top-[250px] lg:left-[255px]',
        'xl:top-[250px] xl:left-[420px]',
        '2xl:left-[540px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ financeTriangleLeftInsideSum }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
        'absolute top-[182px] left-[160px] w-[44px]',
        'md:top-[215px] md:left-[250px]',
        'lg:top-[250px] lg:left-[335px]',
        'xl:top-[250px] xl:left-[500px]',
        '2xl:left-[620px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ financeTriangleRightInsideSum }}</div>
            </div>
            <div
                :class="[
        'absolute top-[325px] left-[140px] w-[44px]',
        'md:top-[420px] md:left-[215px]',
        'lg:top-[480px] lg:left-[294px]',
        'xl:top-[480px] xl:left-[458px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ financeTriangleRightAndLeftUnderSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Հարաբերության Եռանկյունի -->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl  text-white font-bold">
            Հարաբերության Եռանկյունի
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->

          <div
              :class="[
      'absolute top-[95px] w-[257px] left-[32px]',
      'md:top-[95px] md:left-[67px] md:w-[347px]',
      'lg:top-[95px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[95px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="triangle"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
        'absolute top-[200px] left-[50px] w-[44px]',
        'md:top-[240px] md:left-[90px]',
        'lg:top-[275px] lg:left-[150px]',
        'xl:top-[275px] xl:left-[317px]',
        '2xl:left-[435px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ relationshipTriangleLeftUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
        'absolute top-[60px] left-[140px] w-[44px]',
        'md:top-[55px] md:left-[217px]',
        'lg:top-[55px] lg:left-[297px]',
        'xl:top-[55px] xl:left-[457px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#cb0dc3] md:text-[40px] font-thin">{{ relationshipTriangleTop }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
        'absolute top-[200px] left-[225px] w-[44px]',
        'md:top-[240px] md:left-[345px]',
        'lg:top-[275px] lg:left-[450px]',
        'xl:top-[275px] xl:left-[600px]',
        '2xl:left-[740px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ relationshipTriangleRightUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
        'absolute top-[315px] left-[265px] w-[44px]',
        'md:top-[400px] md:left-[395px]',
        'lg:top-[465px] lg:left-[505px]',
        'xl:top-[465px] xl:left-[665px]',
        '2xl:left-[790px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ relationshipTriangleUnderRight }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
        'absolute top-[315px] left-[5px] w-[44px]',
        'md:top-[400px] md:left-[30px]',
        'lg:top-[465px] lg:left-[90px]',
        'xl:top-[465px] xl:left-[245px]',
        '2xl:left-[370px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ relationshipTriangleUnderLeft }}</div>
            </div>


            <!-- Text Element 7 ("7") -->
            <div
                :class="[
        'absolute top-[182px] left-[115px] w-[44px]',
        'md:top-[215px] md:left-[190px]',
        'lg:top-[250px] lg:left-[255px]',
        'xl:top-[250px] xl:left-[420px]',
        '2xl:left-[540px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ relationshipTriangleLeftInsideSum }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
        'absolute top-[182px] left-[160px] w-[44px]',
        'md:top-[215px] md:left-[250px]',
        'lg:top-[250px] lg:left-[335px]',
        'xl:top-[250px] xl:left-[500px]',
        '2xl:left-[620px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ relationshipTriangleRightInsideSum }}</div>
            </div>
            <div
                :class="[
        'absolute top-[325px] left-[140px] w-[44px]',
        'md:top-[420px] md:left-[215px]',
        'lg:top-[480px] lg:left-[294px]',
        'xl:top-[480px] xl:left-[458px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ relationshipTriangleRightAndLeftUnderSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Առողջության Եռանկյունի -->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl  text-white font-bold">
            Առողջության Եռանկյունի
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->

          <div
              :class="[
      'absolute top-[95px] w-[257px] left-[32px]',
      'md:top-[95px] md:left-[67px] md:w-[347px]',
      'lg:top-[95px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[95px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="triangle"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
        'absolute top-[200px] left-[50px] w-[44px]',
        'md:top-[240px] md:left-[90px]',
        'lg:top-[275px] lg:left-[150px]',
        'xl:top-[275px] xl:left-[317px]',
        '2xl:left-[435px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ healthTriangleLeftUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 2 ("3") -->
            <div
                :class="[
        'absolute top-[60px] left-[140px] w-[44px]',
        'md:top-[55px] md:left-[217px]',
        'lg:top-[55px] lg:left-[297px]',
        'xl:top-[55px] xl:left-[457px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#cb0dc3] md:text-[40px] font-thin">{{ healthTriangleTop }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
        'absolute top-[200px] left-[225px] w-[44px]',
        'md:top-[240px] md:left-[345px]',
        'lg:top-[275px] lg:left-[450px]',
        'xl:top-[275px] xl:left-[600px]',
        '2xl:left-[740px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ healthTriangleRightUnderAndTopSum }}</div>
            </div>

            <!-- Text Element 4 ("11") -->
            <div
                :class="[
        'absolute top-[315px] left-[265px] w-[44px]',
        'md:top-[400px] md:left-[395px]',
        'lg:top-[465px] lg:left-[505px]',
        'xl:top-[465px] xl:left-[665px]',
        '2xl:left-[790px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ healthTriangleUnderRight }}</div>
            </div>

            <!-- Text Element 5 ("13") -->
            <div
                :class="[
        'absolute top-[315px] left-[5px] w-[44px]',
        'md:top-[400px] md:left-[30px]',
        'lg:top-[465px] lg:left-[90px]',
        'xl:top-[465px] xl:left-[245px]',
        '2xl:left-[370px]'
      ]"
            >
              <div class="text-2xl text-[#32c5ff] md:text-[40px] font-thin">{{ healthTriangleUnderLeft }}</div>
            </div>


            <!-- Text Element 7 ("7") -->
            <div
                :class="[
        'absolute top-[182px] left-[115px] w-[44px]',
        'md:top-[215px] md:left-[190px]',
        'lg:top-[250px] lg:left-[255px]',
        'xl:top-[250px] xl:left-[420px]',
        '2xl:left-[540px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ healthTriangleLeftInsideSum }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
        'absolute top-[182px] left-[160px] w-[44px]',
        'md:top-[215px] md:left-[250px]',
        'lg:top-[250px] lg:left-[335px]',
        'xl:top-[250px] xl:left-[500px]',
        '2xl:left-[620px]'
      ]"
            >
              <div class="text-lg text-[#adadad] md:text-[30px] font-thin">{{ healthTriangleRightInsideSum }}</div>
            </div>
            <div
                :class="[
        'absolute top-[325px] left-[140px] w-[44px]',
        'md:top-[420px] md:left-[215px]',
        'lg:top-[480px] lg:left-[294px]',
        'xl:top-[480px] xl:left-[458px]',
        '2xl:left-[577px]'
      ]"
            >
              <div class="text-2xl text-[#bd5928] md:text-[40px] font-thin">{{ healthTriangleRightAndLeftUnderSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Ճակատագրական Սխալ -->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl text-white font-bold">
            Ճակատագրական Սխալ
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->
          <div
              :class="[
      'absolute top-[115px] w-[257px] left-[32px]',
      'md:top-[115px] md:left-[67px] md:w-[347px]',
      'lg:top-[115px] lg:left-[111px] lg:w-[417px]',
      'xl:top-[115px] xl:left-[271px] xl:w-[417px]',
      '2xl:left-[396px] 2xl:w-[417px]'
    ]"
          >
            <img
                :src="lastStar"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">

            <!-- Text Element 9 ("6") -->
            <div
                :class="[
            'absolute top-[305px] left-[135px] w-[44px]',
            'md:top-[385px] md:left-[215px]',
            'lg:top-[425px] lg:left-[294px]',
            'xl:top-[425px] xl:left-[458px]',
            '2xl:left-[577px]'
          ]"
            >
              <div class="text-2xl text-red-700 md:text-[40px] font-thin">{{ sumOfUnderLeftAndRightNumbers }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Չակրաներ -->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl text-white font-bold">
            Չակրաներ
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[450px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->
          <div
              :class="[
          'absolute top-[115px] w-[257px] left-[32px]',
          'md:top-[115px] md:left-[67px] md:w-[347px]',
          'lg:top-[115px] lg:left-[111px] lg:w-[417px]',
          'xl:top-[115px] xl:left-[271px] xl:w-[417px]',
          '2xl:left-[396px] 2xl:w-[417px]'
        ]"
          >
            <img
                :src="lastStar"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <!-- Text Elements -->
          <div class="absolute text-white text-center leading-[40px]">
            <!-- Text Element 1 ("13") -->
            <div
                :class="[
            'absolute top-[182px] left-[0px] w-[44px]',
            'md:top-[205px] md:left-[6px]',
            'lg:top-[215px] lg:left-[75px]',
            'xl:top-[215px] xl:left-[230px]',
            '2xl:left-[360px]'
          ]"
            >
              <div class="text-2xl text-yellow-500 md:text-[40px] font-thin">{{ topLeftStarPeak }}</div>
            </div>

            <!-- Text Element 3 ("22") -->
            <div
                :class="[
            'absolute top-[182px] left-[280px] w-[44px]',
            'md:top-[205px] md:left-[433px]',
            'lg:top-[215px] lg:left-[530px]',
            'xl:top-[215px] xl:left-[670px]',
            '2xl:left-[805px]'
          ]"
            >
              <div class="text-2xl text-yellow-500 md:text-[40px] font-thin">{{ topRightStarPeak }}</div>
            </div>


            <!-- Text Element 6 ("8") -->
            <div
                :class="[
            'absolute top-[182px] left-[45px] w-[44px]',
            'md:top-[205px] md:left-[85px]',
            'lg:top-[215px] lg:left-[154px]',
            'xl:top-[215px] xl:left-[295px]',
            '2xl:left-[430px]'
          ]"
            >
              <div class="text-2xl text-blue-500 md:text-[40px] font-thin">{{ chakraLeftNumber }}</div>
            </div>

            <!-- Text Element 7 ("7") -->
            <div
                :class="[
            'absolute top-[182px] left-[90px] w-[44px]',
            'md:top-[205px] md:left-[150px]',
            'lg:top-[217px] lg:left-[215px]',
            'xl:top-[215px] xl:left-[375px]',
            '2xl:left-[497px]'
          ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ dayAndMontSum }}</div>
            </div>

            <!-- Text Element 8 ("6") -->
            <div
                :class="[
            'absolute top-[182px] left-[226px] w-[44px]',
            'md:top-[205px] md:left-[340px]',
            'lg:top-[215px] lg:left-[455px]',
            'xl:top-[215px] xl:left-[595px]',
            '2xl:left-[730px]'
          ]"
            >
              <div class="text-2xl text-blue-500 md:text-[40px] font-thin">{{ chakraRightNumber }}</div>
            </div>

            <!-- Text Element 10 ("8") -->
            <div
                :class="[
            'absolute top-[182px] left-[135px] w-[44px]',
            'md:top-[205px] md:left-[213px]',
            'lg:top-[215px] lg:left-[290px]',
            'xl:top-[215px] xl:left-[453px]',
            '2xl:left-[575px]'
          ]"
            >
              <div class="text-lg text-red-500 md:text-[30px] font-thin">{{ chakraCenterNumber }}</div>
            </div>

            <!-- Text Element 11 ("16") -->
            <div
                :class="[
            'absolute top-[182px] left-[180px] w-[44px]',
            'md:top-[205px] md:left-[270px]',
            'lg:top-[215px] lg:left-[378px]',
            'xl:top-[215px] xl:left-[520px]',
            '2xl:left-[656px]'
          ]"
            >
              <div class="text-2xl md:text-[40px] font-thin">{{ dayAndYearSum }}</div>
            </div>
          </div>

          <!-- Additional Images -->
        </div>

        <!-- Հոգու Ծածկագիր-->
        <div class="mt-16">
          <h1 class="text-xl md:text-3xl text-white font-bold">
            Հոգու Ծածկագիր
          </h1>
        </div>
        <div
            class="relative mx-auto w-full max-w-[318px] md:max-w-[490px] lg:max-w-[640px] xl:max-w-[960px]  2xl:max-w-[1200px] h-[680px] md:h-[650px] lg:h-[670px] xl:h-[590px]">
          <!-- Main Image -->
          <div
              :class="[
          'absolute top-[115px] w-[257px] left-[32px]',
          'md:top-[115px] md:left-[67px] md:w-[347px]',
          'lg:top-[115px] lg:left-[111px] lg:w-[417px]',
          'xl:top-[115px] xl:left-[271px] xl:w-[417px]',
          '2xl:left-[396px] 2xl:w-[417px]'
        ]"
          >
            <img
                :src="lastStar"
                alt="Main Element"
                class="w-full h-auto"
            />
          </div>

          <div class="absolute text-white text-center leading-[40px]">
            <div
                :class="[
                    'absolute top-[233px] left-[135px] w-[44px]',
                    'md:top-[280px] md:left-[215px]',
                    'lg:top-[313px] lg:left-[290px]',
                    'xl:top-[313px] xl:left-[453px]',
                    '2xl:left-[578px]'
                  ]"
            >
              <div class="text-2xl text-green-700 md:text-[40px] font-thin">{{ soulCodeConst }}</div>
            </div>
          </div>

          </div>

      </div>
    </div>
  </div>
</template>

<script>

import Inputmask from "inputmask";
import {onMounted, ref} from "vue";
import logo from '@/assets/images/calculate-logo-black.png';
import firstStar from '@/assets/images/stars/first-star.png';
import lastStar from '@/assets/images/stars/last-star.png';
import triangle from '@/assets/images/stars/triangle.png';

export default {
  name: 'DataCalculator',
  props: {
    msg: String
  },

  setup() {
    const date = ref('');
    const dateInput = ref(null);
    const isCalculate = ref(false);
    const maxNumerologyNumber = 22;
    const day = ref(null);
    const month = ref(null);
    const year = ref(null);
    const topCenterStarPeak = ref(null);
    const topRightStarPeak = ref(null);
    const topLeftStarPeak = ref(null);
    const underRightStarPeak = ref(null);
    const underLeftStarPeak = ref(null);
    const dayAndMontSum = ref(null);
    const dayAndYearSum = ref(null);
    const sumOfUnderLeftAndRightNumbers = ref(null);
    const sumOfTopLeftAndUnderLeftNumbers = ref(null);
    const sumOfAllPeakForCenter = ref(null);
    const monthSum = ref(null);
    const yearSeparateSum = ref(null);
    const sumOfYearSeparateSumAndDayYearMonth = ref(null);
    const chakraCenterNumber = ref(null);
    const chakraLeftNumber = ref(null);
    const chakraRightNumber = ref(null);
    const soulCodeConst = ref(null);
    const fateCodeNumber = ref(null);

    // 1 TRIANGLE
    const personalityUnderLeft = ref(1);
    const personalityTop = ref(2);
    const personalityUnderRight = ref(3);
    const personalityLeftUnderAndTopSum = ref(4);
    const personalityRightUnderAndTopSum = ref(5);
    const personalityRightAndLeftUnderSum = ref(6);
    const personalityLeftInsideSum = ref(7);
    const personalityRightInsideSum = ref(8);

    // 2 TRIANGLE
    const spiritualityUnderLeft = ref(null);
    const spiritualityTop = ref(null);
    const spiritualityUnderRight = ref(null);
    const spiritualityLeftUnderAndTopSum = ref(null);
    const spiritualityRightUnderAndTopSum = ref(null);
    const spiritualityRightAndLeftUnderSum = ref(null);
    const spiritualityLeftInsideSum = ref(null);
    const spiritualityRightInsideSum = ref(null);

    // 3 TRIANGLE
    const financeTriangleUnderLeft = ref(null);
    const financeTriangleTop = ref(null);
    const financeTriangleUnderRight = ref(null);
    const financeTriangleLeftUnderAndTopSum = ref(null);
    const financeTriangleRightUnderAndTopSum = ref(null);
    const financeTriangleRightAndLeftUnderSum = ref(null);
    const financeTriangleLeftInsideSum = ref(null);
    const financeTriangleRightInsideSum = ref(null);

    // 4 TRIANGLE
    const relationshipTriangleUnderLeft = ref(null);
    const relationshipTriangleTop = ref(null);
    const relationshipTriangleUnderRight = ref(null);
    const relationshipTriangleLeftUnderAndTopSum = ref(null);
    const relationshipTriangleRightUnderAndTopSum = ref(null);
    const relationshipTriangleRightAndLeftUnderSum = ref(null);
    const relationshipTriangleLeftInsideSum = ref(null);
    const relationshipTriangleRightInsideSum = ref(null);

    // 5 TRIANGLE
    const healthTriangleUnderLeft = ref(null);
    const healthTriangleTop = ref(null);
    const healthTriangleUnderRight = ref(null);
    const healthTriangleLeftUnderAndTopSum = ref(null);
    const healthTriangleRightUnderAndTopSum = ref(null);
    const healthTriangleRightAndLeftUnderSum = ref(null);
    const healthTriangleLeftInsideSum = ref(null);
    const healthTriangleRightInsideSum = ref(null);

    const reduceToSingleDigit = (number, maxNumerologyNumber) => {
      while (number > maxNumerologyNumber) {
        number = number.toString().split('').reduce((sum, digit) => sum + Number(digit), 0);
      }
      return number;
    }

    const calculateFunction = (data) => {
      const [dayValue, monthValue, yearValue] = data.split('.').map(Number);

      day.value = dayValue;
      month.value = monthValue;
      year.value = yearValue;

      // Top Left Star Peak
      let resultTopLeft = day.value;
      topLeftStarPeak.value = reduceToSingleDigit(resultTopLeft, maxNumerologyNumber);

      // Top Center Star
      monthSum.value = reduceToSingleDigit(month.value, maxNumerologyNumber);
      topCenterStarPeak.value = monthSum.value;

      // Top Right Star
      yearSeparateSum.value = reduceToSingleDigit(year.value, maxNumerologyNumber);
      topRightStarPeak.value = yearSeparateSum.value;

      // Under Right Star
      const allDateSum = topLeftStarPeak.value + topCenterStarPeak.value + topRightStarPeak.value;
      underRightStarPeak.value = reduceToSingleDigit(allDateSum, maxNumerologyNumber);

      // Under Left Star
      const dayMonthYearAndLeftUnderPeatSum = allDateSum + underRightStarPeak.value;
      underLeftStarPeak.value = reduceToSingleDigit(dayMonthYearAndLeftUnderPeatSum, maxNumerologyNumber);

      // Inside Star Calculations
      dayAndMontSum.value = reduceToSingleDigit(topLeftStarPeak.value + topCenterStarPeak.value, maxNumerologyNumber);
      dayAndYearSum.value = reduceToSingleDigit(topCenterStarPeak.value + topRightStarPeak.value, maxNumerologyNumber);
      sumOfYearSeparateSumAndDayYearMonth.value = reduceToSingleDigit(topRightStarPeak.value + underRightStarPeak.value, maxNumerologyNumber);
      sumOfUnderLeftAndRightNumbers.value = reduceToSingleDigit(underRightStarPeak.value + underLeftStarPeak.value, maxNumerologyNumber);
      sumOfTopLeftAndUnderLeftNumbers.value = reduceToSingleDigit(topLeftStarPeak.value + underLeftStarPeak.value, maxNumerologyNumber);
      sumOfAllPeakForCenter.value = reduceToSingleDigit(topLeftStarPeak.value + topCenterStarPeak.value + topRightStarPeak.value + underRightStarPeak.value + underLeftStarPeak.value, maxNumerologyNumber);
    }

    const fateCode = () => {
      const sumDigits = (number) =>
          number
              .toString()
              .split('')
              .reduce((sum, digit) => sum + Number(digit), 0);

      let birthNumber = date.value.split('.').join(''); // Remove dots
      birthNumber = sumDigits(birthNumber);

      while (birthNumber >= 10) {
        birthNumber = sumDigits(birthNumber); // Continue reducing until single digit
      }

      fateCodeNumber.value = reduceToSingleDigit(birthNumber, maxNumerologyNumber);
    }

    const calculateDate = () => {
      const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
      if (!datePattern.test(date.value)) {
        return;
      }

      calculateFunction(date.value);
      fateCode();
      personalityTriangle();
      spiritualityTriangle();
      financeTriangle();
      relationshipTriangle();
      healthTriangle();
      chakras();
      soulCode();

      isCalculate.value = true;

      setTimeout(() => {
        const firstErrorInput = document.querySelector('#starsComponent');
        if (firstErrorInput) {
          firstErrorInput.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
      }, 1);
    }



    const personalityTriangle = () => {
      personalityUnderLeft.value = sumOfTopLeftAndUnderLeftNumbers.value
      personalityTop.value = topLeftStarPeak.value
      personalityUnderRight.value = dayAndMontSum.value
      personalityLeftUnderAndTopSum.value = reduceToSingleDigit(personalityUnderLeft.value + personalityTop.value, maxNumerologyNumber);
      personalityRightUnderAndTopSum.value = reduceToSingleDigit(personalityTop.value + personalityUnderRight.value, maxNumerologyNumber);
      personalityRightAndLeftUnderSum.value = reduceToSingleDigit(personalityUnderLeft.value + personalityUnderRight.value, maxNumerologyNumber);
      personalityLeftInsideSum.value = reduceToSingleDigit(personalityLeftUnderAndTopSum.value + personalityRightUnderAndTopSum.value, maxNumerologyNumber);
      personalityRightInsideSum.value = reduceToSingleDigit(personalityTop.value + personalityRightAndLeftUnderSum.value, maxNumerologyNumber);
    }

    const spiritualityTriangle = () => {
      spiritualityUnderLeft.value = dayAndMontSum.value
      spiritualityTop.value = topCenterStarPeak.value
      spiritualityUnderRight.value = dayAndYearSum.value
      spiritualityLeftUnderAndTopSum.value = reduceToSingleDigit(spiritualityUnderLeft.value + spiritualityTop.value, maxNumerologyNumber);
      spiritualityRightUnderAndTopSum.value = reduceToSingleDigit(spiritualityTop.value + spiritualityUnderRight.value, maxNumerologyNumber);
      spiritualityRightAndLeftUnderSum.value = reduceToSingleDigit(spiritualityUnderLeft.value + spiritualityUnderRight.value, maxNumerologyNumber);
      spiritualityLeftInsideSum.value = reduceToSingleDigit(spiritualityLeftUnderAndTopSum.value + spiritualityRightUnderAndTopSum.value, maxNumerologyNumber);
      spiritualityRightInsideSum.value = reduceToSingleDigit(spiritualityTop.value + spiritualityRightAndLeftUnderSum.value, maxNumerologyNumber);
    }

    const financeTriangle = () => {
      financeTriangleUnderLeft.value = dayAndYearSum.value
      financeTriangleTop.value = topRightStarPeak.value
      financeTriangleUnderRight.value = sumOfYearSeparateSumAndDayYearMonth.value
      financeTriangleLeftUnderAndTopSum.value = reduceToSingleDigit(financeTriangleUnderLeft.value + financeTriangleTop.value, maxNumerologyNumber);
      financeTriangleRightUnderAndTopSum.value = reduceToSingleDigit(financeTriangleTop.value + financeTriangleUnderRight.value, maxNumerologyNumber);
      financeTriangleRightAndLeftUnderSum.value = reduceToSingleDigit(financeTriangleUnderLeft.value + financeTriangleUnderRight.value, maxNumerologyNumber);
      financeTriangleLeftInsideSum.value = reduceToSingleDigit(financeTriangleLeftUnderAndTopSum.value + financeTriangleRightUnderAndTopSum.value, maxNumerologyNumber);
      financeTriangleRightInsideSum.value = reduceToSingleDigit(financeTriangleTop.value + financeTriangleRightAndLeftUnderSum.value, maxNumerologyNumber);
    }

    const relationshipTriangle = () => {
      relationshipTriangleUnderLeft.value = sumOfYearSeparateSumAndDayYearMonth.value
      relationshipTriangleTop.value = underRightStarPeak.value
      relationshipTriangleUnderRight.value = sumOfUnderLeftAndRightNumbers.value
      relationshipTriangleLeftUnderAndTopSum.value = reduceToSingleDigit(relationshipTriangleUnderLeft.value + relationshipTriangleTop.value, maxNumerologyNumber);
      relationshipTriangleRightUnderAndTopSum.value = reduceToSingleDigit(relationshipTriangleTop.value + relationshipTriangleUnderRight.value, maxNumerologyNumber);
      relationshipTriangleRightAndLeftUnderSum.value = reduceToSingleDigit(relationshipTriangleUnderLeft.value + relationshipTriangleUnderRight.value, maxNumerologyNumber);
      relationshipTriangleLeftInsideSum.value = reduceToSingleDigit(relationshipTriangleLeftUnderAndTopSum.value + relationshipTriangleRightUnderAndTopSum.value, maxNumerologyNumber);
      relationshipTriangleRightInsideSum.value = reduceToSingleDigit(relationshipTriangleTop.value + relationshipTriangleRightAndLeftUnderSum.value, maxNumerologyNumber);
    }

    const healthTriangle = () => {
      healthTriangleUnderLeft.value = sumOfUnderLeftAndRightNumbers.value
      healthTriangleTop.value = underLeftStarPeak.value
      healthTriangleUnderRight.value = sumOfTopLeftAndUnderLeftNumbers.value
      healthTriangleLeftUnderAndTopSum.value = reduceToSingleDigit(healthTriangleUnderLeft.value + healthTriangleTop.value, maxNumerologyNumber);
      healthTriangleRightUnderAndTopSum.value = reduceToSingleDigit(healthTriangleTop.value + healthTriangleUnderRight.value, maxNumerologyNumber);
      healthTriangleRightAndLeftUnderSum.value = reduceToSingleDigit(healthTriangleUnderLeft.value + healthTriangleUnderRight.value, maxNumerologyNumber);
      healthTriangleLeftInsideSum.value = reduceToSingleDigit(healthTriangleLeftUnderAndTopSum.value + healthTriangleRightUnderAndTopSum.value, maxNumerologyNumber);
      healthTriangleRightInsideSum.value = reduceToSingleDigit(healthTriangleTop.value + healthTriangleRightAndLeftUnderSum.value, maxNumerologyNumber);
    }

    const chakras = () => {
      chakraCenterNumber.value = reduceToSingleDigit(dayAndYearSum.value + dayAndMontSum.value, maxNumerologyNumber);
      chakraLeftNumber.value = reduceToSingleDigit(topLeftStarPeak.value + dayAndMontSum.value, maxNumerologyNumber);
      chakraRightNumber.value = reduceToSingleDigit(topRightStarPeak.value + dayAndYearSum.value, maxNumerologyNumber);
    }

    const soulCode = () => {
      let getStarPeakSum = topCenterStarPeak.value + topRightStarPeak.value + topLeftStarPeak.value + underRightStarPeak.value + underLeftStarPeak.value;
      soulCodeConst.value = reduceToSingleDigit(getStarPeakSum, maxNumerologyNumber);
    }

    onMounted(() => {

      console.log(process.env.NODE_ENV);
      console.log(process.env.VUE_APP_BASE_URL);

      Inputmask("99.99.9999", {
        // placeholder: "дд.мм.гггг",
        // placeholder: "օօ.աա.տտտտ",
        placeholder: "dd.mm.yyyy",
        clearMaskOnLostFocus: false,
        showMaskOnHover: false,
      }).mask(dateInput.value);
    });

    return {
      date,
      dateInput,
      logo,
      firstStar,
      lastStar,
      triangle,
      isCalculate,
      day,
      month,
      year,
      topCenterStarPeak,
      topRightStarPeak,
      topLeftStarPeak,
      underLeftStarPeak,
      underRightStarPeak,
      dayAndMontSum,
      dayAndYearSum,
      sumOfUnderLeftAndRightNumbers,
      sumOfTopLeftAndUnderLeftNumbers,
      sumOfAllPeakForCenter,
      monthSum,
      sumOfYearSeparateSumAndDayYearMonth,
      fateCodeNumber,
      // 1 TRIANGLE
      personalityUnderLeft,
      personalityTop,
      personalityUnderRight,
      personalityLeftUnderAndTopSum,
      personalityRightUnderAndTopSum,
      personalityRightAndLeftUnderSum,
      personalityLeftInsideSum,
      personalityRightInsideSum,
      // 2 TRIANGLE
      spiritualityUnderLeft,
      spiritualityTop,
      spiritualityUnderRight,
      spiritualityLeftUnderAndTopSum,
      spiritualityRightUnderAndTopSum,
      spiritualityRightAndLeftUnderSum,
      spiritualityLeftInsideSum,
      spiritualityRightInsideSum,
      // 3 TRIANGLE
      financeTriangleUnderLeft,
      financeTriangleTop,
      financeTriangleUnderRight,
      financeTriangleLeftUnderAndTopSum,
      financeTriangleRightUnderAndTopSum,
      financeTriangleRightAndLeftUnderSum,
      financeTriangleLeftInsideSum,
      financeTriangleRightInsideSum,
      // 4 TRIANGLE
      relationshipTriangleUnderLeft,
      relationshipTriangleTop,
      relationshipTriangleUnderRight,
      relationshipTriangleLeftUnderAndTopSum,
      relationshipTriangleRightUnderAndTopSum,
      relationshipTriangleRightAndLeftUnderSum,
      relationshipTriangleLeftInsideSum,
      relationshipTriangleRightInsideSum,
      // 5 TRIANGLE
      healthTriangleUnderLeft,
      healthTriangleTop,
      healthTriangleUnderRight,
      healthTriangleLeftUnderAndTopSum,
      healthTriangleRightUnderAndTopSum,
      healthTriangleRightAndLeftUnderSum,
      healthTriangleLeftInsideSum,
      healthTriangleRightInsideSum,
      // chakra
      chakraCenterNumber,
      chakraLeftNumber,
      chakraRightNumber,
      // last
      soulCodeConst,
      calculateDate
    };
  }
}
</script>